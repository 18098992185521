<template>
  <div>
    <h4 class="mb-2">
      {{ $t(title || "Hostes", locale) }}
    </h4>

    <b-row
      no-gutters
      class="d-flex justify-content-between border border-solid p-1"
      :class="{ 'mb-2': alertText }"
    >
      <!-- ADULTS -->
      <b-col cols="12" md="4" class="text-center mb-1 mb-md-0">
        <feather-icon icon="UserIcon" class="text-primary mb-1" size="32" />
        <p class="mb-50 font-weight-bold">
          {{ adultsText }}
        </p>
        <small>{{ adultsAgesText }}</small>
      </b-col>

      <!-- CHILDREN -->
      <b-col cols="12" md="4" class="text-center mb-1 mb-md-0">
        <feather-icon icon="UserIcon" class="text-primary mb-1" size="32" />
        <p class="mb-50 font-weight-bold">
          {{ childrenText }}
        </p>
        <p class="mb-50">
          <small>{{ $t("emails.common.childrenAgeDefinition", locale) }}</small>
        </p>
      </b-col>

      <!-- BABIES -->
      <b-col cols="12" md="4" class="text-center">
        <feather-icon icon="UserIcon" class="text-primary mb-1" size="32" />
        <p class="mb-50 font-weight-bold">
          {{ babiesText }}
        </p>
        <p class="mb-50">
          <small>{{ $t("emails.common.babiesAgeDefinition", locale) }}</small>
        </p>
      </b-col>
    </b-row>

    <!-- ALERT -->
    <alert v-if="alertText" variant="secondary" :text="alertText" />
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import Alert from "@/views/bookings/booking/components/mail/Alert.vue";
import {
  getAdultsText,
  getBabiesText,
  getChildrensText,
} from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    Alert,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    alertText: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    guests() {
      return this.$store.getters["booking/guests"];
    },
    adultsText() {
      return getAdultsText(this.booking.adults, this.locale);
    },
    adultsAgesText() {
      if (!this.guests.length) return this.$t("Adults", this.locale);
      const text = [];
      this.guests.forEach((guest) => {
        text.push(guest.age);
      });
      return `${text.join(", ")} ${this.$t(
        "AnysEdat",
        this.locale
      ).toLowerCase()}`;
    },
    childrenText() {
      return getChildrensText(this.booking.children, this.locale);
    },
    babiesText() {
      return getBabiesText(this.booking.babies, this.locale);
    },
  },
};
</script>
