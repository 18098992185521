<template>
  <div class="mb-0">
    <b-alert class="mb-50" :variant="variant" :show="true">
      <div class="alert-body">
        {{ text }}
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BAlert } from "bootstrap-vue";

export default {
  components: {
    BAlert,
  },
  props: {
    variant: {
      type: String,
      default: "secondary",
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
