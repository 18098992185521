<template>
  <b-row no-gutters>
    <b-col cols="12" class="border-bottom">
      <h5 class="font-weight-bold p-2 mb-0 text-center bg-light">
        {{ subject }}
      </h5>
    </b-col>
    <b-col cols="12" class="p-2">
      <!-- INTRO -->
      <intro
        class="mb-3"
        :name="ownerFirstName"
        :text="
          $t('emails.ownerInstructions.intro', locale, {
            clientName: client.fullName,
            localizator: booking.localizator,
            accommodationName: accommodation.name,
          })
        "
      />

      <!-- CHECK-IN AND CHECKOUT TIMES -->
      <div class="mb-4">
        <h4 class="font-weight-bold mb-2">
          {{ $t("emails.ownerInstructions.timesTitle", locale) }}
        </h4>
        <b-row
          no-gutters
          class="d-flex justify-content-between border border-solid p-1"
        >
          <!-- CHECKIN -->
          <b-col cols="12" md="6" class="text-center mb-1 mb-md-0">
            <p class="font-weight-bolder mb-1">
              {{ $t("Checkin", locale) }}
            </p>
            <feather-icon
              icon="ClockIcon"
              class="text-primary mb-1"
              size="32"
            />
            <p class="mb-50">
              {{ checkinDay }}
            </p>
            <div>
              <small>{{ checkinTime }}</small>
            </div>
            <div v-if="checkinTransportText">
              <small>{{ checkinTransportText }}</small>
            </div>
          </b-col>

          <!-- CHECKOUT -->
          <b-col cols="12" md="6" class="text-center">
            <p class="font-weight-bolder mb-1">
              {{ $t("Checkout", locale) }}
            </p>
            <feather-icon
              icon="ClockIcon"
              class="text-primary mb-1"
              size="32"
            />
            <p class="mb-50">
              {{ checkoutDay }}
            </p>
            <div>
              <small>{{ checkoutTime }}</small>
            </div>
            <div v-if="checkoutTransportText">
              <small>{{ checkoutTransportText }}</small>
            </div>
          </b-col>
        </b-row>

        <b-alert class="mt-1" :show="true" variant="secondary">
          <div class="alert-body">
            {{ $t("emails.ownerInstructions.timesNote", locale) }}
          </div>
        </b-alert>
      </div>

      <!-- GUESTS -->
      <guests-description
        class="mb-2"
        :title="$t('emails.ownerInstructions.guestsTitle', locale)"
        :locale="locale"
      />

      <!-- ATTACH GUESTS REPORT -->
      <div class="mb-4">
        <b-form-checkbox v-model="content.attachGuestsReport" class="mb-2">
          <span class="cursor-pointer noselect d-inline">
            {{ $t("emails.ownerInstructions.attachGuestsReport", locale) }}
          </span>
        </b-form-checkbox>
        <b-alert :show="true" variant="secondary">
          <div class="alert-body">
            <span v-if="content.attachGuestsReport">
              {{ $t("emails.common.attachedGuestsReport", locale) }}
            </span>
            <span v-else>
              {{
                $t("emails.ownerInstructions.notAttachedGuestsReport", locale)
              }}
            </span>
          </div>
        </b-alert>
      </div>

      <!-- KEYS COLLECTION -->
      <div class="mb-4">
        <h4 class="font-weight-bold mb-2">
          {{ $t("emails.ownerInstructions.keysTitle", locale) }}
        </h4>
        <b-row no-gutters class="d-flex justify-content-between">
          <!-- SELF CHECK-IN -->
          <b-col
            cols="12"
            md="6"
            class="border border-solid p-1 cursor-pointer mb-1 mb-md-0"
            :class="{ 'border-primary': content.selfCheckin }"
            @click="content.selfCheckin = true"
          >
            <p class="font-weight-semibold mb-1">
              <b-form-checkbox
                v-model="content.selfCheckin"
                class="pointer-events-none"
              >
                {{ $t("emails.ownerInstructions.keysSelfCheckin", locale) }}
              </b-form-checkbox>
            </p>
            <div class="text-center">
              <feather-icon
                icon="KeyIcon"
                class="text-primary my-2"
                size="32"
              />
              <!-- KEYS LOCATION -->
              <template v-if="content.keysLocation">
                <p class="mb-50">
                  <small class="text-left font-weight-bolder">
                    {{ $t("emails.ownerInstructions.keysLocation", locale) }}
                  </small>
                </p>
                <small>
                  {{ content.keysLocation }}
                </small>
              </template>

              <!-- LOCKBOX CODE -->
              <template v-if="content.lockboxCode">
                <p class="mt-1 mb-50">
                  <small class="font-weight-bolder">
                    {{ $t("emails.ownerInstructions.keysLockboxCode", locale) }}
                  </small>
                </p>
                <small>
                  {{ content.lockboxCode }}
                </small>
              </template>
            </div>
          </b-col>

          <!-- OWNER CHECK-IN -->
          <b-col
            cols="12"
            md="6"
            class="border border-dashed p-1 cursor-pointer"
            :class="{ 'border-primary': content.ownerCheckin }"
            @click="content.ownerCheckin = true"
          >
            <p class="font-weight-semibold mb-1">
              <b-form-checkbox
                v-model="content.ownerCheckin"
                class="pointer-events-none"
              >
                {{ $t("emails.ownerInstructions.keysOwnerCheckin", locale) }}
              </b-form-checkbox>
            </p>
            <div class="text-center">
              <feather-icon
                icon="UserIcon"
                class="text-primary my-2"
                size="32"
              />
              <p class="mb-50">
                <small class="font-weight-bolder">{{
                  $t("Recorda", locale)
                }}</small>
              </p>
              <small>
                {{
                  $t("emails.ownerInstructions.keysOwnerInstructions", locale)
                }}
              </small>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- PAYMENTS -->
      <div class="mb-4">
        <h4 class="font-weight-bold mb-2">
          {{ $t("emails.ownerInstructions.paymentsTitle", locale) }}
        </h4>
        <b-list-group>
          <b-list-group-item
            v-for="(contentPayment, index) in contentPayments"
            :key="`content-payment-${index}`"
            class="p-1"
          >
            <div class="d-flex justify-content-between">
              <div class="mb-25 pr-1">
                {{ contentPayment.title }}
              </div>
              <span>
                {{ contentPayment.price }}
              </span>
            </div>
            <small>
              <b-badge pill :variant="contentPayment.badgeVariant">
                {{ contentPayment.badgeText }}
              </b-badge>
            </small>
          </b-list-group-item>
        </b-list-group>
      </div>

      <!-- REQUESTS -->
      <div class="mb-4">
        <h4 class="font-weight-bold mb-2">
          {{ $t("emails.ownerInstructions.requestsTitle", locale) }}
        </h4>
        <b-list-group>
          <b-list-group-item
            v-for="(contentRequest, index) in contentRequests"
            :key="`content-request-${index}`"
            class="d-flex justify-content-between p-1"
          >
            <div class="d-flex align-items-center">
              <span class="mr-2">
                <feather-icon
                  :icon="contentRequest.check ? 'CheckIcon' : 'XIcon'"
                  size="20"
                />
              </span>
              <div>
                <h6 class="mb-25">
                  {{ contentRequest.title }}
                </h6>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>

      <!-- CONTACT -->
      <div>
        <h4 class="font-weight-bold mb-2">
          {{ $t("emails.ownerInstructions.contactTitle", locale) }}
        </h4>
        <b-row class="mb-2">
          <b-col cols="12">
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <span class="mr-2">
                    <feather-icon icon="MailIcon" size="20" />
                  </span>
                  <div>
                    <h6 class="mb-25">
                      {{ $t("Email", locale) }}
                    </h6>
                    <b-card-text>info@foravilarentals.com</b-card-text>
                  </div>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <span class="mr-2">
                    <feather-icon icon="PhoneIcon" size="20" />
                  </span>
                  <div>
                    <h6 class="mb-25">
                      {{ $t("Telèfon oficina", locale) }}
                    </h6>
                    <b-card-text>
                      +34 971 541 247 (WhatsApp,
                      {{ $t("Trucada", locale) }})
                    </b-card-text>
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BBadge,
  BCardText,
  BAlert,
} from "bootstrap-vue";
import { formatCurrency, formatDateStringToDate } from "@/utils/formatters";
import {
  getBookingServiceOwnerTotalPrice,
  getBookingServiceClientUnitPriceText,
  getServiceName,
  getTransportName,
  getPaymentFrequencyUnit,
  getKeysLocationName,
} from "@/utils/methods";
import Intro from "@/views/bookings/booking/components/mail/Intro.vue";
import GuestsDescription from "@/views/bookings/booking/components/mail/GuestsDescription.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BBadge,
    BCardText,
    Intro,
    GuestsDescription,
    BAlert,
  },
  props: {
    locale: {
      type: String,
      default: "en-GB",
    },
  },
  data() {
    return {
      content: {},
      getServiceName,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    specialRequests() {
      return this.$store.getters["booking/specialRequests"];
    },
    hasSecurityDeposit() {
      return this.$store.getters["booking/hasSecurityDeposit"];
    },
    hasChargableSecurityDeposit() {
      return this.$store.getters["booking/hasChargableSecurityDeposit"];
    },
    securityDepositPrice() {
      return this.$store.getters["booking/securityDepositPrice"];
    },
    ownerSubtotalPrice() {
      return this.$store.getters["booking/ownerSubtotal"];
    },
    accommodationExtras() {
      return this.$store.getters["booking/accommodationExtras"];
    },
    ownerChargableServices() {
      return this.$store.getters["booking/ownerChargableServices"];
    },
    contentOwnerChargableServices() {
      if (!this.ownerChargableServices) return [];
      return this.ownerChargableServices.map((bookingService) => ({
        name: getServiceName(bookingService.service, this.locale),
        paymentTime: this.getServicePaymentTime(bookingService.paymentTime),
        price: this.getBookingServiceOwnerTotalPrice(bookingService),
      }));
    },
    ownerUnchargableServices() {
      return this.$store.getters["booking/ownerUnchargableServices"];
    },
    contentOwnerUnchargableServices() {
      if (!this.ownerUnchargableServices) return [];
      return this.ownerUnchargableServices.map((bookingService) => ({
        name: getServiceName(bookingService.service, this.locale),
        paymentTime: this.getServicePaymentTime(bookingService.paymentTime),
        price: bookingService.pvpPrice,
        unit:
          this.getPaymentFrequencyUnit(bookingService.paymentFrequency) || "",
      }));
    },
    contentPayments() {
      const payments = [];

      // Accommodation price
      payments.push({
        title: this.$t("Allotjament", this.locale),
        price: this.formatCurrency(this.ownerSubtotalPrice),
        badgeText: this.$t("Cobrat per", this.locale, {
          gestor: "Foravila Rentals",
        }),
        badgeVariant: "success",
      });

      // Security deposit
      if (this.hasSecurityDeposit) {
        payments.push({
          title: this.$t("Fiança", this.locale),
          price: this.formatCurrency(this.securityDepositPrice),
          badgeText: this.hasChargableSecurityDeposit
            ? this.$t("Cobrat per", this.locale, { gestor: "Foravila Rentals" })
            : this.$t(
                "emails.ownerInstructions.ownerHasToChargeOnCheckin",
                this.locale
              ),
          badgeVariant: this.hasChargableSecurityDeposit
            ? "success"
            : "warning",
        });
      }

      // Chargable services
      if (this.ownerChargableServices?.length) {
        this.ownerChargableServices.forEach((bookingService) => {
          payments.push({
            title: this.getServiceName(bookingService.service, this.locale),
            price: this.getBookingServiceOwnerTotalPriceText(bookingService),
            badgeText: this.$t("Cobrat per", this.locale, {
              gestor: "Foravila Rentals",
            }),
            badgeVariant: "success",
          });
        });
      }

      // Unchargable services
      if (this.ownerUnchargableServices?.length) {
        this.ownerUnchargableServices.forEach((bookingService) => {
          payments.push({
            title: this.getServiceName(bookingService.service, this.locale),
            price: this.getBookingServiceClientUnitPriceText(bookingService),
            badgeText: this.getServicePaymentTime(bookingService),
            badgeVariant: "warning",
          });
        });
      }

      return payments;
    },
    contentRequests() {
      const requests = [];
      requests.push({
        title: this.babyCotsText,
        check: !!this.details?.babyCots,
      });

      requests.push({
        title: this.babyChairsText,
        check: !!this.details?.babyChairs,
      });

      if (this.accommodationExtras.length) {
        this.accommodationExtras.forEach((bookingService) => {
          requests.push({
            title: this.getServiceName(bookingService.service, this.locale),
            check: true,
          });
        });
      }

      if (this.specialRequests.length) {
        this.specialRequests.forEach((specialRequest) => {
          requests.push({
            title: specialRequest.request,
            check: true,
          });
        });
      }
      return requests;
    },
    guests() {
      return this.$store.getters["booking/guests"];
    },
    reportableGuests() {
      return this.$store.getters["booking/reportableGuests"];
    },
    formattedReportableGuests() {
      // Format the dates so they are displayed correctly in the generated pdf
      if (!this.reportableGuests?.length) {
        return null;
      }

      return this.reportableGuests.map((guest) => ({
        ...guest,
        birthDate: formatDateStringToDate(guest.birthDate),
        idIssueDate: formatDateStringToDate(guest.idIssueDate),
      }));
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    clientFirstName() {
      return this.client?.firstName;
    },
    details() {
      return this.$store.getters["booking/details"];
    },
    contract() {
      return this.$store.getters["booking/contract"];
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    owner() {
      return this.$store.getters["booking/owner"];
    },
    ownerFirstName() {
      return this.owner?.firstName;
    },
    checkinDay() {
      return formatDateStringToDate(this.booking.checkin, this.locale);
    },
    checkinTime() {
      return this.details?.accommodationArrivalTime
        ? `${this.details?.accommodationArrivalTime} h`
        : `16:00 h`;
    },
    checkoutDay() {
      return formatDateStringToDate(this.booking.checkout, this.locale);
    },
    checkoutTime() {
      return this.details?.accommodationDepartureTime
        ? `${this.details?.accommodationDepartureTime} h`
        : `10:00 h`;
    },
    adultsAgesText() {
      if (!this.guests.length) return this.$t("Adults", this.locale);
      const text = [];
      this.guests.forEach((guest) => {
        text.push(guest.age);
      });
      return `${text.join(", ")} ${this.$t(
        "AnysEdat",
        this.locale
      ).toLowerCase()}`;
    },
    babyCotsText() {
      const babyCotTranslation = this.$t("Cuna", this.locale).toLowerCase();
      const babyCotsTranslation = this.$t("Cunes", this.locale).toLowerCase();
      if (!this.details.babyCots)
        return this.$t(
          "emails.ownerInstructions.requestsNoBabyCots",
          this.locale
        );
      if (this.details.babyCots !== 1)
        return `${this.details.babyCots} ${babyCotsTranslation}`;
      return `1 ${babyCotTranslation}`;
    },
    babyChairsText() {
      const babyChairTranslation = this.$t("Trona", this.locale).toLowerCase();
      const babyChairsTranslation = this.$t(
        "Trones",
        this.locale
      ).toLowerCase();
      if (!this.details.babyChairs)
        return this.$t(
          "emails.ownerInstructions.requestsNoBabyChairs",
          this.locale
        );
      if (this.details.babyChairs !== 1)
        return `${this.details.babyChairs} ${babyChairsTranslation}`;
      return `1 ${babyChairTranslation}`;
    },
    checkinTransportText() {
      if (!this.details) return null;
      if (!this.details.arrivalTransport) return null;
      if (this.details.arrivalTransport === "NONE") return null;

      const transportName = getTransportName(
        this.details.arrivalTransport,
        this.locale
      );
      const transportDetails = [];
      if (this.details.arrivalTransportLocalizator)
        transportDetails.push(this.details.arrivalTransportLocalizator);
      if (this.details.transportArrivalTime)
        transportDetails.push(this.details.transportArrivalTime);

      return `${transportName}: ${transportDetails.join(" - ")}`;
    },
    checkoutTransportText() {
      if (!this.details) return null;
      if (!this.details.departureTransport) return null;
      if (this.details.departureTransport === "NONE") return null;

      const transportName = getTransportName(
        this.details.departureTransport,
        this.locale
      );
      const transportDetails = [];
      if (this.details.departureTransportLocalizator)
        transportDetails.push(this.details.departureTransportLocalizator);
      if (this.details.transportDepartureTime)
        transportDetails.push(this.details.transportDepartureTime);

      return `${transportName}: ${transportDetails.join(" - ")}`;
    },
    subject() {
      return `${this.$t("emails.ownerInstructions.subject", this.locale, {
        localizator: this.booking.localizator,
      })}`;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    "content.selfCheckin": function (value) {
      this.content.ownerCheckin = !value;
    },
    // eslint-disable-next-line func-names
    "content.ownerCheckin": function (value) {
      this.content.selfCheckin = !value;
    },
    // eslint-disable-next-line func-names
    "content.attachGuestsReport": function (value) {
      this.content.reportableGuests = value
        ? this.formattedReportableGuests
        : null;
    },
    reportableGuests(guests) {
      this.content.attachGuestsReport = !!guests.length;
    },
    content: {
      deep: true,
      handler() {
        this.$emit("content-updated", this.content);
      },
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    initInputs() {
      this.content = {
        locale: this.locale,
        subject: this.subject,
        localizator: this.booking?.localizator || null,
        ownerEmail: this.owner?.email || null,
        ownerSecondaryEmail: this.owner?.secondaryEmail || null,
        ownerName: this.ownerFirstName || null,
        clientName: this.clientFirstName || null,
        accommodationName: this.accommodation?.name || null,
        accommodationCity: this.accommodation?.location?.city || null,
        accommodationRegion: this.accommodation?.location?.region || null,
        checkinDay: this.checkinDay,
        checkinTime: this.details?.accommodationArrivalTime || null,
        checkinTransport: this.checkinTransportText || null,
        checkoutDay: this.checkoutDay,
        checkoutTime: this.details?.accommodationDepartureTime || null,
        checkoutTransport: this.checkoutTransportText || null,
        adults: this.booking?.adults || 0,
        adultsAges: this.adultsAgesText || null,
        children: this.booking?.children || 0,
        babies: this.booking?.babies || 0,
        attachGuestsReport: !!this.reportableGuests.length,
        reportableGuests: this.formattedReportableGuests,
        selfCheckin: !!this.contract?.selfCheckin,
        ownerCheckin: !this.contract?.selfCheckin,
        lockboxCode: this.accommodation?.details?.lockboxCode || null,
        keysLocation: getKeysLocationName(
          this.contract?.keysLocation || null,
          this.$i18n.locale
        ),
        payments: this.contentPayments,
        requests: this.contentRequests,
      };
    },
    formatCurrency(value) {
      return formatCurrency(value) || null;
    },
    getServicePaymentTime(bookingService) {
      if (!bookingService) return null;
      switch (bookingService.paymentTime) {
        case "CHECK_IN":
          return this.$t(
            "emails.ownerInstructions.ownerHasToChargeOnCheckin",
            this.locale
          );
        default:
          return this.$t(
            "emails.ownerInstructions.ownerHasToChargeOnCheckout",
            this.locale
          );
      }
    },
    getBookingServiceOwnerTotalPrice(bookingService) {
      return getBookingServiceOwnerTotalPrice(
        bookingService,
        this.booking.nights
      );
    },
    getBookingServiceOwnerTotalPriceText(bookingService) {
      return formatCurrency(
        this.getBookingServiceOwnerTotalPrice(bookingService)
      );
    },
    getBookingServiceClientUnitPriceText(bookingService) {
      return getBookingServiceClientUnitPriceText(bookingService, this.locale);
    },
    getPaymentFrequencyUnit(code) {
      return getPaymentFrequencyUnit(code, this.locale);
    },
  },
};
</script>
